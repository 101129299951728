import React from "react";
import { useParams } from 'react-router-dom';
import { ProjectsContent } from '../../Content';
import "./ProjectDetail.css";

export default function ProjectDetail() {
  const { id } = useParams();
  const { name, image, description, skills, link } = ProjectsContent[id];

  return (
    <div className="projectDetail">
      <h1 className="projectDetail-header">{ name }</h1>
      <div className="projectDetail-top">
        <img className="projectDetail-img" src={ image } alt="project_img"/>
        <div className="projectDetail-description">
          <h2 className="projectDetail-h2"><b>Description</b></h2>
          <p className="projectDetail-p">{ description }</p>
          <h2 className="projectDetail-h2"><b>Technologies</b></h2>
          <p className="projectDetail-p">{ skills }</p>
        </div>
      </div>
      <div className="projectDetail-bottom">
        <div
          className="projectDetail-gitlabIcon"
          onClick={event => window.open(`${ link }`)}
          rel="noopener noreferrer"
        > 
          <span
            className="iconify"
            data-icon="mdi:gitlab"
            width="70"
          />
          <p className="projectDetail-bottom-p">Go To Project Repo</p>
        </div>
      </div>
    </div>
  );
}
