import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import ReorderIcon from '@material-ui/icons/Reorder';
import Resume from '../../assets/GreathouseResume.pdf'
import { ProjectsContent } from "../../Content";
import './Navbar.css';

export default function Navbar() {
  const [expandNavbar, setExpandNavbar] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setExpandNavbar(false);
  }, [location]);

  return (
    <div className="navbar" id={expandNavbar ? "open" : "close"}>
      <div className="navbar-toggleButton">
        <button onClick={() => setExpandNavbar((prev) => !prev)}>
          <ReorderIcon />
        </button>
      </div>
      {location.pathname === "/" ? 
        <div className="navbar-links">
          <Link className="navbar-link" to="/">Home</Link>
          <a className="navbar-link-a" href="#projects">Projects</a>
          <a className="navbar-link-a" href="#experience">Experience</a>
          <a className="navbar-link-a" href={Resume} download="GreathouseResume.pdf">Resume</a>
        </div>
        :
        <>
          <Link className="navbar-link" to="/">Home</Link>
          <div className="navbar-links-projects">
            {ProjectsContent.map(({name}, idx) => (
              <Link 
                className="navbar-link"
                to={`/projects/${ idx }`}
                key={ idx }
                name={ name }  
              >
                { name }
              </Link>
            ))}
          </div>
        </>
      }
    </div>
  );
}
