import React from "react";
import { useNavigate } from "react-router-dom";
import "./ProjectItem.css";

export default function ProjectItem({ image, name, id }) {
  const navigate = useNavigate();
  return (
    <div
      className="projectItem"
      onClick={() => navigate(`/projects/${ id }`)}
    >
      <div style={{ backgroundImage: `url(${ image })` }} className="projectItem-bgImage"/>
      <h1 className="projectItem-header"> { name } </h1>
    </div>
  );
}
