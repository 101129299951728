import React from "react";
import './SkillsItem.css';

export default function SkillsItem({ header, skills }) {
  return (
    <li className="skills-list-item">
      <h3 className="skill-item-header">{header}</h3>
      <p className="skill-item">{skills}</p>
    </li>
  );
}
