import React from "react";
import { SkillsContent } from '../../Content';
import SkillsItem from './skillsItem/SkillsItem';
import './Skills.css';

export default function Skills() {
  return (
    <div className="skills">
      <h2 className="skills-header">Skills</h2>
      <ol className="skills-list">
        {SkillsContent.map(({ header, skills }, idx) => (
          <SkillsItem
            key={ idx }
            header={ header }
            skills={ skills }
          />
        ))}
      </ol>
    </div>
  );
}
