import './Intro.css';

export default function Intro() {
  return (
    <div className="intro">
      <p className="intro-hello">Hello, my name is</p>
      <h1 className="intro-fullname">Anthony Jared Greathouse</h1>
      <p className="intro-tagline">A software developer passionate about crafting the future with integrity and empathy, one line of code at a time.</p>
    </div>
  )
}
