import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import ProjectDetail from './pages/projectDetail/ProjectDetail';
import ScrollToTop from "./components/scrollToTop";
import './App.css';

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar/>
        <Routes>
          <Route path="/" element= {<Home />} />
          <Route path="/projects/:id" element= {<ProjectDetail />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
