import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import './Footer.css'

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-socialMedia">
        <p> &copy; 2023 GreathouseDev.me</p>
        <LinkedInIcon
          onClick={event => window.open("https://linkedin.com/in/anthony-j-greathouse")}
          rel="noopener noreferrer"
        />
        <GitHubIcon
          onClick={event => window.open("https://github.com/Jared-Greathouse")}
          rel="noopener noreferrer"
        />
        <div
          onClick={event => window.open("https://gitlab.com/Jared-Greathouse")}
          rel="noopener noreferrer"
          >
          <span className="iconify" data-icon="mdi:gitlab"/>
        </div>
        <p> a.jaredgreathouse@gmail.com </p>
      </div>
    </div>
  );
}
