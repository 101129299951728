import React from "react";
import Intro from '../../components/intro/Intro';
import Projects from "../../components/projects/Projects";
import Experience from "../../components/experience/Experience";
import Skills from "../../components/skills/Skills";
import Jared_Profile from '../../assets/Jared_Profile.JPG';
import './Home.css';

export default function Home() {
  return (
    <>
      <div className="home">
        <Intro />
        <div className="home-about">
          <img className="home-photo" src={Jared_Profile} alt="Jared"/>
          <Skills/>
        </div>
        <Projects />
        <Experience />
      </div>
      {/* <Footer /> */}
    </>
  );
}
