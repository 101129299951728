import React from "react";
import ProjectItem from './projectItem/ProjectItem';
import { ProjectsContent } from "../../Content";
import "./Projects.css";

export default function Projects() {
  return (
    <div className="projects" id="projects">
      <h1 className="projects-projectTitle"> My Personal Projects </h1>
      <div className="projects-projectList">
        {ProjectsContent.map(({name, image}, idx) => (
          <ProjectItem
            id={idx}
            key={idx}
            name={name}
            image={image}
          />
        ))}
      </div>
    </div>
  );
}
