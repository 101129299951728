import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import StarIcon from '@material-ui/icons/Star';
import GreathouseHRCoC from '../../assets/GreathouseHRCoC.png'
import Diploma from '../../assets/Diploma.png'
import 'react-vertical-timeline-component/style.min.css';
import './Experience.css';

export default function Experience() {
  return (
    <div className="experience" id='experience'>
      <h1 className="experience-header">Experience Timeline</h1>
      <VerticalTimeline lineColor="#D7C9AA" contentStyle={{color: "#252D2B" }} >
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Aug 2022 - Dec 2022"
          dateClassName={"dateStyle"}
          iconStyle={{ background: "#3D0B37", color: "#fff" }}
          icon={<SchoolIcon />}
          contentStyle={{ background: "#c9e4ca", color: "#0b0f12" }}
        >
          <h3 className="vertical-timeline-element-title">
            Certificate of Completion
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Galvanize / Hack Reactor
          </h4>
          <h5 className="vertical-timeline-element-subtitle">
            Pleasanton, CA (Remote)
          </h5>
          <img src={GreathouseHRCoC} alt="" width="300px" height="250px"/>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work vertical-timeline-element"
          contentStyle={{background: "#c9e4ca", color: "#0b0f12" }}
          date="Sept 2015 - Jun 2022"
          dateClassName={"dateStyle"}
          iconStyle={{ background: "#2374AB", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Customer Network Engineer II
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            NCR
          </h4>
          <h5 className="vertical-timeline-element-subtitle">
            Pleasanton, CA
          </h5>
          <p>
            <li>
              Diagnosed the root cause of equipment failures
              so well that 90% of service calls did not
              require a second tech dispatch inside a 13 week span.
            </li>
            <li>
              Maximized coverage for our customers by faithfully volunteering to train on equipment too rare or critical for the team's general knowledge base.
            </li>
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jun 2014 - Jul 2015"
          dateClassName={"dateStyle"}
          iconStyle={{ background: "#2374AB", color: "#fff" }}
          icon={<WorkIcon />}
          contentStyle={{background: "#c9e4ca", color: "#0b0f12" }}
        >
          <h3 className="vertical-timeline-element-title">
            Housekeeping and Laundry Supervisor
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Majestic Inn & Spa
          </h4>
          <h5 className="vertical-timeline-element-subtitle">
            Anacortes, WA
          </h5>
          <p>
            <li>
              Standardized the training of new Housekeepers by writing a sixty page training manual which delineated all housekeeping best practices.
            </li>
            <li>
              Retained staff by analyzing reservation data to create a schedule that reflected the labor demand and released it far enough in advance to satisfy staff.
            </li>
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="May 2014"
          dateClassName={"dateStyle"}
          iconStyle={{ background: "#3D0B37", color: "#fff" }}
          icon={<SchoolIcon />}
          contentStyle={{background: "#c9e4ca", color: "#0b0f12"}}
        >
          <h3 className="vertical-timeline-element-title">
            Associate of Arts Degree, Social Science
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Las Positas College
          </h4>
          <h5 className="vertical-timeline-element-subtitle">
            Livermore, California
          </h5>
          <img src={Diploma} alt="" width="300px" height="250px" />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: '#DD6E42', color: '#fff'}}
          icon={<StarIcon />}
        />
      </VerticalTimeline>
    </div>
  );
}
