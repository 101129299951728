import chat1 from "./assets/chat1.png"
import DealershipManager1 from "./assets/DealershipManager1.png"
import DealershipManager2 from "./assets/DealershipManager2.png"
import ConferenceGO1 from "./assets/ConferenceGO1.png"
import ConferenceGO2 from "./assets/ConferenceGO2.png"
import AIMusicApp from './assets/AI-Music-App.webp'
import MirrorLoop from './assets/mirror-loop.jpeg'
import scrumptious from './assets/scrumptious-meal-plan.webp';

export const ProjectsContent = [
  {
    name: "AI-Music-App",
    image: AIMusicApp,
    image2: "get it",
    description: "AI-music-App is a front-end ReactJS app that I designed, developed, and deployed on AWS. It is designed to consume APIs serving files from a database of music generated by AI. This app will allow searching and filtering for tracks by name, date created, length, models, genres, instruments, and datasets. The selected tracks can be played and downloaded in app. It also has a user interface for selecting the criteria for a new ai generated batch of tracks. This app would be used as an internal tool by a media licensing company for searching and generating tracks according to a customers criteria.",
    skills: "JavaScript · React · Front-End Development · Continuous Integration and Continuous Delivery (CI/CD) · HTML · CSS · Git",
    link: "https://gitlab.com/Jared-Greathouse/AI-Music-App"
  },
  {
    name: "Portfolio V1",
    image: MirrorLoop,
    image2: "get it",
    description: "This is the first iteration of my portfolio. It is a static site built with React showcasing my skills, projects, experience, and contact information",
    skills: "Skills: JavaScript · React · Front-End Development · HTML · CSS · Git",
    link: "https://gitlab.com/Jared-Greathouse/portfolio",
  },
  {
    name: "Chat-Catch",
    image: chat1,
    image2: "get it",
    description: "Chat-Catch is a full stack chat application. This was the final project for my Hack Reactor course. The chat application uses a database and websockets for realtime chat with persistent message histories beyond a single session.",
    skills: "JavaScript · Python · React · Full-Stack Development · Front-End Development · FastAPI · Continuous Integration and Continuous Delivery (CI/CD) · WebSocket · MongoDB · HTML · CSS · Docker Products · Git",
    link: "https://gitlab.com/Jared-Greathouse/chat-catch"
  },
  {
    name: "Dealership-Manager",
    image: DealershipManager1,
    image2: DealershipManager2,
    description: "A full stack application designed for internal use at a car dealership. It keeps track of data related to employees in sales and service. It allows for scheduling new vehicle service appointments and updating lot inventory as vehicles are sold. It was the final project of my second module during my Hack Reactor Bootcamp. This application was built by a two person team",
    skills: "JavaScript · Python · React · Full-Stack Development · Front-End Development · Django · PostgreSQL · HTML · CSS · Bootstrap · Microservices · Docker Products · Git",
    link: "https://gitlab.com/Jared-Greathouse/Dealership-Manager",
  },
  {
    name: "Conference-GO",
    image: ConferenceGO1,
    image2: ConferenceGO2,
    description: "Conference Go is a full stack application for managing and attending conferences. New conferences can be created using date and location. Existing conferences can be updated to add presentations and attendence. This was the first major application I built during my Hack Reactor bootcamp.",
    skills: "JavaScript · Python · React · Full-Stack Development · Front-End Development · Django · HTML · CSS · Microservices · Docker Products · Git",
    link: "https://gitlab.com/Jared-Greathouse/fearless-frontend",
  },
  {
    name: "Scrumptious",
    image: scrumptious,
    image2: "get it",
    description: "Scrumptious is a full stack app designed to explore and add recipes and meal plans",
    skills: "Skills: JavaScript · React · Front-End Development · HTML · CSS · Git",
    link: "https://gitlab.com/Jared-Greathouse/scrumptious-hardening",
  },
];

export const SkillsContent = [
  {
    header: "Front-End",
    skills: "ReactJS · React Hooks · Redux · DOM manipulation · BootStrap · MaterialUI"
  },
  {
    header: "Back-End",
    skills: "NodeJS · REST · GraphQL · PostgreSQL · MongoDB · Django · FastAPI · WebSockets"
  },
  {
    header: "Languages",
    skills: "JavaScript · TypeScript · HTML · CSS · Python · SQL"
  },
  {
    header: "Design",
    skills: "Domain-Driven Design · Test-Driven Development · Microservices · CI/CD"
  }
];
